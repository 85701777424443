<template>
  <span :class="statusColor">
    <b>
      {{ $t(`call-reporting.table.call-directions.${rowData.direction}`) }}
    </b>
  </span>
</template>

<script>
export default {
  name: 'CallDirection',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusColor() {
      switch (this.rowData.direction) {
        case 'out_going':
          return 'text-primary'
        case 'incoming':
          return 'text-success'
        case 'local':
          return 'text-info'
        case 'cross_tenants':
          return 'text-secondary'
        default:
          return 'text-danger'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  max-width: 20px;
  height: auto;
}
</style>

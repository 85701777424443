<template>
  <span :class="'text-start ' + statusColor">
    <b>
      {{ $t(`call-reporting.table.call-statuses.${rowData.call_status}`) }}
    </b>
  </span>
</template>

<script>
export default {
  name: 'CallStatus',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusColor() {
      switch (this.rowData.call_status) {
        case 'Answered':
          return 'text-success'
        case 'Not Answered':
          return 'text-danger'
        case 'Failed':
          return 'text-danger'
        case 'Busy':
          return 'text-danger'
        default:
          return 'text-secondary'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}
@media (max-width: 1365px) {
  img {
    position: static;
    left: unset;
  }
}
</style>
